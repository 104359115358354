"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAbTestContext = exports.Variant = void 0;
const VARIANT_KEY = 'cookie_banner_variant';
const AB_ENABLED_KEY = 'cookie_banner_abtest_enabled';
var Variant;
(function (Variant) {
    Variant[Variant["Control"] = 0] = "Control";
    Variant[Variant["Variant1"] = 1] = "Variant1";
    Variant[Variant["Variant2"] = 2] = "Variant2";
    Variant[Variant["Variant3"] = 3] = "Variant3";
})(Variant || (exports.Variant = Variant = {}));
function getVariant() {
    const value = window.localStorage.getItem(VARIANT_KEY);
    if (!value) {
        return Variant.Control;
    }
    const variant = parseInt(value);
    if (!(variant in Variant)) {
        return Variant.Control;
    }
    return variant;
}
function isEnabled() {
    const value = window.localStorage.getItem(AB_ENABLED_KEY);
    return value === 'true';
}
function getAbTestContext() {
    var _a;
    const EXTRA_CLASSES = {
        [Variant.Control]: [],
        [Variant.Variant1]: ['cookie-disclaimer-variant1'],
        [Variant.Variant2]: ['cookie-disclaimer-variant2'],
        [Variant.Variant3]: ['cookie-disclaimer-variant3', ...(((_a = window.WH) === null || _a === void 0 ? void 0 : _a.NGS) ? ['cookie-disclaimer-variant3--ngs'] : [])],
    };
    const enabled = isEnabled();
    const variant = enabled ? getVariant() : Variant.Control;
    return {
        enabled,
        variant,
        toolbarCssClasses: EXTRA_CLASSES[variant] || [],
    };
}
exports.getAbTestContext = getAbTestContext;
